import '../css/app.css'
import Alpine from 'alpinejs'
import baguetteBox from 'baguettebox.js'

Alpine.start()

document.addEventListener('DOMContentLoaded', function () {
  baguetteBox.run('.baguettes', {
    noScrollbars: true,
    async: true
  })

  // const nav = document.querySelector('nav')
  // const activeLink = nav.querySelector('.nav-active')
  //
  // function updateDecoration () {
  //   if (activeLink) {
  //     nav.style.setProperty('--decoration-left', activeLink.offsetLeft + 19 + 'px')
  //     nav.style.setProperty('--decoration-width', activeLink.offsetWidth - 38 + 'px')
  //     nav.style.setProperty('--decoration-display-before', 'block')
  //   } else {
  //     nav.style.setProperty('--decoration-left', '0')
  //     nav.style.setProperty('--decoration-width', '0')
  //   }
  // }
  //
  // nav.addEventListener('mouseover', function (event) {
  //   if (event.target.tagName === 'A') {
  //     nav.style.setProperty('--decoration-left', event.target.offsetLeft + 19 + 'px')
  //     nav.style.setProperty('--decoration-width', event.target.offsetWidth - 38 + 'px')
  //     nav.style.setProperty('--decoration-display-before', 'block')
  //   }
  // })
  //
  // nav.addEventListener('mouseout', function () {
  //   updateDecoration()
  // })
  //
  // window.addEventListener('resize', updateDecoration)
  //
  // updateDecoration()
})
